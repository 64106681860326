import { useContext, useMemo, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { useTranslation } from 'next-i18next';
import ReactDatePicker from 'react-datepicker';
import { isWeekend, addBusinessDays, max, add } from 'date-fns';
// import moment from 'moment';
// import { format } from 'date-fns';
import { LoadingIndicator, Modal, ThemeContext } from '@forma/forma-ui-kit';
// import TimezoneSelect from '../TimezoneSelect';
import TimeItems from '../TimeItems';

import styles from './date-time-modal.module.css';

const getUserTimezone = () => {
  // return moment().format('Z');
  return -new Date().getTimezoneOffset() / 60;
};

const getMinDateISO = () => addBusinessDays(new Date(), 1).toISOString();

const ModalTitle = ({ isPhone, step, onClickBack }) => {
  const { t } = useTranslation();

  if (isPhone) return (
    <div className={styles.modalTitle}>
      {step === 0 && t('date')}
      {step === 1 && (
        <><ReactSVG src="/icons/arrow-left.svg" className={styles.modalTitleIcon} wrapper="span" onClick={onClickBack} /> {t('time')}</>
      )}
    </div>
  );

  return (
    <>Выберите дату и время для проведения демо</>
  );
};

const DateTimeModal = ({ open, onClose, onChange, dateValue, gmtValue, timeslots }) => {
  const { t, i18n } = useTranslation();
  const { viewport } = useContext(ThemeContext);
  const [slot, setSlot] = useState(timeslots?.find(({ id }) => id === dateValue?.slot));
  const [value, setValue] = useState(dateValue || getMinDateISO());
  // moment(addBusinessDays(new Date(), 1)).format('YYYY-MM-DDTHH:mm:ssZ')
  const [utc] = useState(gmtValue || { utc: getUserTimezone() });
  const [step, setStep] = useState(0);

  const handleChangeDate = (date) => {
    setValue(date.toISOString());
    // setValue(date.toISOString().replace('Z', utc.utc));
    setSlot(null);
  };

  const handleChangeTime = (id, date) => {
    setValue(date);
    setSlot(id);
  };

  // const handleChangeUTC = (value) => {
  //   setUtc(value);
  // };

  const handleClickSave = () => {
    onChange(value, utc, slot);
    setStep(0);
  };

  const handleClickNext = () => {
    setStep(prev => prev + 1);
  };

  const dates = useMemo(() => timeslots?.reduce((acc, current) => {
    const date = current.date.slice(0, 10);
    const slot = { id: current.id, date: current.date, available: current.available };
    const next = {
      date: date,
      dateObj: new Date(date),
      available: current.available || acc[date]?.available || false,
      slots: acc[date] ? [...acc[date].slots, slot] : [slot]
    };
    return ({ ...acc, [date]: next });
  }, {}), [timeslots]);


  const disabledDates = useMemo(() => dates && Object.values(dates)
    .filter(({ available, dateObj }) => !available || isWeekend(dateObj))
    .map(({ dateObj }) => dateObj), [dates]);

  const isPhone = viewport === 'phone';

  const valueDate = value.slice(0, 10);

  return (
    <Modal
      open={open}
      onClose={onClose}
      width="100%"
      maxWidth="842px"
      title={<ModalTitle isPhone={isPhone} step={step} onClickBack={() => setStep(0)} />}
      buttons={[
        {
          className: styles.modalButton,
          viewStyle: 'primary',
          onClick: (isPhone && step !== 1) ? handleClickNext : handleClickSave,
          children: (isPhone && step !== 1) ? t('next') : t('save'),
          closing: (!isPhone || step === 1),
          disabled: (!isPhone || step === 1) && !slot
        }
      ]}
    >
      <div className={styles.modal}>
        <div className={styles.modalContent}>
          {!timeslots ? (
            <div className={styles.loader}>
              <LoadingIndicator color="var(--primary-color)" />
            </div>
          ) : (
            <>
              {(!isPhone || step === 0) && (
                <ReactDatePicker
                  selected={new Date(value)}
                  onChange={handleChangeDate}
                  excludeDates={disabledDates}
                  minDate={addBusinessDays(new Date(), 1)}
                  maxDate={dates ? max(Object.values(dates).map(({ dateObj }) => dateObj)) : add(new Date(), { months: 1 })}
                  previousMonthButtonLabel={
                    <img src="/icons/arrow-left.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                  }
                  nextMonthButtonLabel={
                    <img src="/icons/arrow-right.svg" className={styles.arrow} width="22px" height="16px" alt="" />
                  }
                  locale={i18n.language}
                  inline
                />
              )}

              {/* <div className={styles.timeHead}>
                {viewport !== 'phone' && t('what_is_the_best_time')}
                <TimezoneSelect value={utc} onChange={handleChangeUTC} />
              </div> */}
              {(!isPhone || step === 1) && (
                <TimeItems
                  utc={utc}
                  slots={dates && dates[valueDate] && dates[valueDate].slots}
                  // slots={(dates && dates[moment(value).format('yyyy-MM-DD')]) && dates[moment(value).format('yyyy-MM-DD')].slots}
                  value={slot}
                  onChange={handleChangeTime}
                />
              )}
            </>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default DateTimeModal;
