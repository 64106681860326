import { forwardRef, useState } from 'react';
// import moment from 'moment';
import { Input } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import { format } from 'date-fns';
import ClientWrap from '@/components/ClientWrap';
import DateTimeModal from './DateTimeModal';

import styles from './date-time-input.module.css';

const DateTimeInput = forwardRef(({
  onChange, maxLength = 50, register, value, timeslots, className, meta, ...props
}, ref) => {
  const [isShowModal, setShowModal] = useState(false);

  const handleChangeDate = (value, utc, slot) => {
    onChange({ date: value, utc, slot });
    setShowModal(false);
  };

  return (
    <>
      <Input
        {...props}
        {...register}
        className={classNames(className, styles.input)}
        onClick={() => setShowModal(true)}
        maxLength={maxLength}
        value={value ? format(new Date(value.date), 'dd.MM.yyyy HH:mm') : ''}
        // value={value ? moment(value.date).utcOffset(value.utc.utc).format('DD.MM.yyyy HH:mm') : ''}
        meta={{
          ...meta,
          icon: <img src="/icons/calendar.svg" width="100%" height="100%" alt="" />,
          showIconAlways: true
        }}
        readOnly
        ref={ref}
      />
      <ClientWrap>
        <DateTimeModal
          open={isShowModal}
          onClose={() => setShowModal(false)}
          onChange={handleChangeDate}
          dateValue={value?.date}
          gmtValue={value?.utc}
          timeslots={timeslots}
        />
      </ClientWrap>
    </>
  );
});

DateTimeInput.displayName = 'DateTimeInput';

export default DateTimeInput;
