import { CSSProperties, ReactNode } from 'react';
import classNames from 'classnames';

import styles from './page-block.module.css';

interface PageBlockProps {
  id?: string,
  className?: string,
  style?: CSSProperties,
  title?: ReactNode,
  children: ReactNode,
  variant?: 'default' | 'white' | 'green'
}

const PageBlock = ({ id, className, style, title, children, variant }: PageBlockProps) => {
  return (
    <div className={classNames(styles.root, className, variant && styles[variant])} style={style} id={id}>
      <div className="container">
        {title && <h3 className={styles.title}>{title}</h3>}
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default PageBlock;
