import { Button } from '@forma/forma-ui-kit';
import classNames from 'classnames';
import { format } from 'date-fns';
// import moment from 'moment';

import styles from './time-items.module.css';

const TimeItems = ({ utc, slots, value, onChange }) => {
  return (
    <div className={styles.items}>
      {slots?.map(({ id, date, available }) => (
        <Button
          viewStyle="secondary"
          className={classNames(styles.item, !available && styles.disabled, value === id && styles.selected)}
          onClick={() => onChange(id, date)}
          key={id}
        >
          {format(new Date(date), 'HH:mm')}
          {/* {moment(date).utcOffset(utc.utc).format('HH:mm')} */}
        </Button>
      ))}
    </div>
  );
};

export default TimeItems;
