import { useState } from 'react';
import { requests } from '@/helpers/api';

const useRequestDemo = ({ lang, roistatVisit }) => {
  const [formStatus, setFormStatus] = useState(null);
  const [saveResult, setSaveResult] = useState(null);

  const onSubmit = async (values) => {
    if (!values.date_time?.utc?.utc || !values.date_time?.slot) {
      setFormStatus('error');
      return false;
    }

    const data = {
      timeslotId: values.date_time.slot,
      clientPhone: values.phone,
      clientEmail: values.email ?? 'demo@client.com',
      companyName: values.company_name,
      companySize: values.company_size,
      clientFullName: values.name ?? 'Не указано',
      gmt: values.date_time.utc.utc ?? '0',
      chanel: values.social ?? 'Звонок',
      roistatVisit
    };

    const result = await requests.setTimeslot(data, lang);
    if (result) {
      setFormStatus('success');
      setSaveResult({ date: values.date_time.date, utc: values.date_time.utc.utc });
      return true;
    }

    setFormStatus('error');
    return false;
  };

  const reset = () => {
    setFormStatus(null);
    setSaveResult(null);
  };

  return { onSubmit, saveResult, isSuccess: formStatus === 'success', isError: formStatus === 'error', reset };
};

export { useRequestDemo };
