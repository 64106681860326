import axios from 'axios';

export const api = axios.create({
  baseURL: process.env.MOCK_API_URL,
  headers: {
    Accept: 'application/json',
  },
});

export const requests = {
  sendAmoTcRequest: async (data, lang = 'ru') => {
    return await axios
      .post(`${process.env.API_URL}/amo/tcrequest`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data)
      .catch((error) => null);
  },
  sendContactsFrom: async (data, lang = 'ru') => {
    return await axios
      .post(`${process.env.API_URL}/landing/contact`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data)
      .catch((error) => null);
  },
  setTimeslot: async (data, lang = 'ru') => {
    return await axios
      .post(`${process.env.API_URL}/timeslots`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data)
      .catch((error) => null);
  },
  sendResume: async (data, lang = 'ru') => {
    return await axios
      .post(`${process.env.API_URL}/landing/resume`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data)
      .catch((error) => null);
  },
  sendExternalFill: async (key, data, lang = 'ru') => {
    return await axios
      .put(`${process.env.API_URL}/externalinfillspublic/${key}`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data);
  },
  sendDataToRenderTemplatesBySeo: async (key, data, lang = 'ru') => {
    return await axios
      .post(
        `${process.env.API_URL}/externalinfillspublic/${key}/render`,
        data,
        { responseType: 'blob', 'X-Language': lang }
      )
      .then((res) => res.data);
  },
  sendExternalAttachments: async (key, data, lang = 'ru') => {
    return await axios
      .post(
        `${process.env.API_URL}/externalinfillspublic/${key}/attachments`,
        data,
        { headers: { 'X-Language': lang } }
      )
      .then((res) => res.data);
  },
  renderTemplateFromSeo: async (id, data, lang = 'ru') => {
    return await axios
      .post(
        `${process.env.API_URL}/sharedtemplatespublic/${id}/createrender`,
        data,
        { headers: { 'X-Language': lang } }
      )
      .then((res) => res.data);
  },
  sendDataToEmail: async (id, mail, lang = 'ru') => {
    return await axios
      .post(`${process.env.API_URL}/sharedrenderspublic/${id}/send`, mail, { headers: { 'X-Language': lang } })
      .then((res) => res.data);
  },
  sendSignCode: async (key, data, lang = 'ru') => {
    return await axios
      .put(`${process.env.API_URL}/externalinfillspublic/${key}/signrequest`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data);
  },
  signDocumentSubmit: async (key, data, lang = 'ru') => {
    return await axios
      .put(`${process.env.API_URL}/externalinfillspublic/${key}/signaccept`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data);
  },
  signDocumentDecline: async (key, data, lang = 'ru') => {
    return await axios
      .put(`${process.env.API_URL}/externalinfillspublic/${key}/signreject`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data);
  },
  signDocumentComment: async (key, data, lang = 'ru') => {
    return await axios
      .put(`${process.env.API_URL}/externalinfillspublic/${key}/signcomment`, data, { headers: { 'X-Language': lang } })
      .then((res) => res.data);
  },
  signDocumentPreview: async (key, data, lang = 'ru') => {
    return await axios
      .put(`${process.env.API_URL}/externalinfillspublic/${key}/preview`, data, { headers: { 'X-Language': lang }, responseType: 'blob' })
      .then((res) => res.data);
  },
};

export const queries = {
  getMainData: async (lang) => {
    return await api.get(`/${lang}/main.json`).then((res) => res.data);
  },
  getPageData: async (lang, slug) => {
    return await api.get(`/${lang}/${slug}.json`).then((res) => res.data);
  },
  getPromoData: async (lang, slug) => {
    return await api.get(`/${lang}/promo/${slug}.json`).then((res) => res.data);
  },
  getHTMLPageData: async (lang, slug) => {
    return await api.get(`/${lang}/pages/${slug}.html`).then((res) => res.data);
  },
  getTimezones: async (lang) => {
    return await api.get(`/${lang}/timezones.json`).then((res) => res.data);
  },
  getPagesList: async (lang, slug) => {
    return await api
      .get(`/${lang}/pages/${slug}/index.json`)
      .then((res) => res.data);
  },
  getTariffsData: async () => {
    return await api
      .get(`${process.env.API_URL}/tariffspublic/`)
      .then((res) => res.data);
  },
  getSignatureTariffs: async () => {
    return await api
      .get(`${process.env.API_URL}/workspace/signaturetariffs/`)
      .then((res) => res.data);
  },
  getTimeslots: async () => {
    return await api
      .get(`${process.env.API_URL}/timeslots`)
      .then((res) => res.data);
  },
  getExternalFillItem: async (key) => {
    return await api
      .get(`${process.env.API_URL}/externalinfillspublic/${key}`)
      .then((res) => res.data);
  },
  getExternalFillAttachment: async (key, id) => {
    return await api
      .get(`${process.env.API_URL}/externalinfillspublic/${key}/attachments/${id}`, { responseType: 'blob' })
      .then((res) => res.data);
  },
  getExternalFillOwner: async (wid, id) => {
    return await api
      .get(`${process.env.API_URL}/externalinfillentities/${wid}/${id}`)
      .then((res) => res.data);
  },
  getSignDocumentStatus: async (key, data, lang = 'ru') => {
    return await axios
      .get(`${process.env.API_URL}/externalinfillspublic/${key}/sign`, { headers: { 'X-Language': lang } })
      .then((res) => res.data);
  },
  getSeoData: async (lang, slug) => {
    return await api.get(`/${lang}/seo/${slug}.json`).then((res) => res.data);
  },
  getSeoTemplateByID: async (id) => {
    return await axios
      .get(`${process.env.API_URL}/sharedtemplatespublic/${id}`)
      .then((res) => res.data);
  },
  getSeoTemplateContent: async (id) => {
    return await axios
      .get(`${process.env.API_URL}/sharedtemplatespublic/${id}/content`)
      .then((res) => res.data);
  },
  getSeoTemplateSides: async (id) => {
    return await axios
      .get(`${process.env.API_URL}/sharedtemplatespublic/${id}/sides`)
      .then((res) => res.data);
  },
  getSeoTemplatePreviews: async (id) => {
    return await axios
      .get(`${process.env.API_URL}/sharedtemplatespublic/${id}/previews`)
      .then((res) => res.data);
  },
  getSharedFolders: async () => {
    return await axios
      .get(`${process.env.API_URL}/sharedfolderspublic`)
      .then((res) => res.data);
  },
  getSharedTemplates: async () => {
    return await axios
      .get(`${process.env.API_URL}/sharedtemplatespublic`)
      .then((res) => res.data);
  },
  getMailDomain: async (mail) => {
    return await axios
      .get(`${process.env.API_URL}/maildomain?email=${mail}`)
      .then((res) => res.data);
  },
  sendToRenderDocument: async (idTemplate, idDocument, type) => {
    return await axios
      .get(
        `${process.env.API_URL}/sharedtemplatespublic/${idTemplate}/render/${idDocument}?format=${type}`,
        {
          responseType: 'blob',
        }
      )
      .then((res) => res.data);
  },
};
